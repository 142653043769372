import {
  Product,
  ProductBundle,
  ProductBundleGallery,
  ProductBundleSlot,
  ProductBundleVariantMap,
  ProductOption,
  ProductOptionName,
  ProductOptionSettings,
  ProductOptionValue,
  ProductVariant,
  SanityColorCode,
  Seo,
} from '@data/sanity/schema'
import { SanityContentFragment } from './content'
import { SanityImageFragment } from './image'
import { SanitySiteFragment } from './site'
import {
  HasContent,
  HasPrivateContent,
  HasRawContent,
  SanityHasSlug,
} from './page'

export interface SanityProductGalleryPhoto {
  forOption: string
  photos: SanityImageFragment[]
}

export interface SanityProductListingPhoto {
  forOption: string
  default: SanityImageFragment
  hover?: SanityImageFragment
}

export interface SanityProductPhotosFragment {
  main?: SanityProductGalleryPhoto[]
  listing?: SanityProductListingPhoto[]
}

export type SanityProductVariantOption = Pick<
  ProductOptionValue,
  'name' | 'position' | 'value'
>

export type SanityProductVariantFragment = Pick<
  ProductVariant,
  | 'sku'
  | 'barcode'
  | 'title'
  | 'price'
  | 'comparePrice'
  | 'inStock'
  | 'lowStock'
  | 'seo'
> & {
  id: SanityProductVariantId
  options: SanityProductVariantOption[]
}

export interface SanityProductOption {
  name: string
  position: number
  values: string[]
}

export interface SanityProductOptionSetting {
  forOption?: string
  color?: SanityColorCode
}

export interface SanityProductOptionName {
  forOption?: string
  name: string
}

export enum SanityProductType {
  PHYSICAL = 'physical',
  DIGITAL = 'digital',
  SUBSCRIPTION = 'subscription',
}

interface SanityProductFilter {
  forOption: string
  slug: string
}

export type SanityProductFragment = SanityHasSlug & {
  createdAt: string
  description?: SanityContentFragment[]
  surfaceOption?: string
  options: SanityProductOption[]
  optionSettings?: SanityProductOptionSetting[]
  optionNames?: SanityProductOptionName[]
  id: SanityProductId
  photos: SanityProductPhotosFragment
  variants?: SanityProductVariantFragment[]
  filters?: SanityProductFilter[]
  type: 'physical' | 'digital' | 'subscription'
  sku?: string
  barcode?: string
  title: string
  price: number
  comparePrice: number
  inStock: boolean
  lowStock: boolean
  useGallery?: string
}

export type SanityProductId = Product['productID']

export type SanityProductVariantId = ProductVariant['variantID']

export type SanityProductPage = Pick<
  Product,
  'hasTransparentHeader' | 'seo' | 'noIndex'
> &
  HasContent &
  HasPrivateContent & {
    product: SanityProductFragment
    featuredProductIds?: SanityProductId[]
  }

export interface SanityProductPageQuery {
  page: SanityProductPage | null
  site: SanitySiteFragment
}

export type SanityProductSeo = Omit<Seo, '_type'>

export type SanityProduct = Omit<
  Product,
  '_id' | '_type' | '_createdAt' | '_updatedAt' | '_rev'
>

export type SanityProductByShopifyId = Pick<
  ProductVariant,
  '_id' | 'locale'
> & {
  description?: SanityContentFragment[]
} & HasRawContent

export type SanityProductsByShopifyIdQuery = SanityProductByShopifyId[]

export type SanityProductVariantProduct = Pick<Product, 'title'> &
  SanityHasSlug & {
    id: Product['productID']
  }

export interface SanityProductVariantPhotosCart {
  forOption: string
  default: SanityImageFragment
}

export interface SanityProductVariantPhotos {
  cart?: SanityProductVariantPhotosCart[]
}

export type SanityProductVariant = Omit<
  ProductVariant,
  '_id' | '_type' | '_createdAt' | '_updatedAt' | '_rev'
>

export interface ProductVariantOption {
  name: string
  value: string
  position: number
}

export type SanityProductVariantQuery = Pick<
  ProductVariant,
  'title' | 'price' | 'sellingPlanId'
> & {
  id: SanityProductVariantId
  product: SanityProductVariantProduct
  photos: SanityProductVariantPhotos
  options: SanityProductVariantOption[]
}

export type SanityProductVariantsQuery = SanityProductVariantQuery[]

export type SanityProductVariantsByShopifyIdQuery = Pick<
  ProductVariant,
  '_id' | 'variantID'
>[]

export type SanityProductCatalogueItem = Pick<Product, 'type' | 'title'> &
  SanityHasSlug & {
    id: Product['productID']
    subscriptionProductIds?: Product['productID'][]
  }

export type SanityProductCatalogueQuery = SanityProductCatalogueItem[]

export type SanityProductExistsQuery = Pick<Product, '_id'>

export type SanityProductBundleSlotProduct = SanityProductFragment

export type SanityProductBundleSlot = Pick<
  ProductBundleSlot,
  'name' | 'showSelection' | 'label'
> & {
  selectedOptionNames: {
    productId: string
    list: string[]
  }[]
  products: SanityProductBundleSlotProduct[]
}

export interface SanityProductBundleVariantMapProduct {
  id: string
  variantIds: string[]
}

export type SanityProductBundleVariantMap = Pick<
  ProductBundleVariantMap,
  'name'
> & {
  products: SanityProductBundleVariantMapProduct[]
}

export type SanityProductBundleGallery = Pick<
  ProductBundleGallery,
  'variantCombination'
> & {
  photos: SanityImageFragment[]
}

export type SanityProductBundle = Pick<ProductBundle, 'name'> & {
  slots: SanityProductBundleSlot[]
  variantMap: SanityProductBundleVariantMap[]
  gallery: SanityProductBundleGallery[] | null
}
