import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { motion } from 'framer-motion'
import { useRef, useContext, useEffect, useState, RefObject } from 'react'

import { SanityHeaderFragment } from '@data/sanity/queries/types/site'
import { SanityImageFragment } from '@data/sanity/queries/types/image'
import { useIntersectionObserver } from '@lib/dom-interaction-observer'
import { useRect } from '@lib/dom-rect'
import { SiteContext } from '@lib/site'
import { StringsContext } from '@lib/strings'

import Menu from '@blocks/navigation/menu'
import MegaNavigation from '@blocks/navigation/mega-navigation'
import PromoBar from '../promo-bar'
import Hamburger from './hamburger'
import Logo from './logo'

export interface HeaderSizeValues {
  height: number
  bottom: number
}

interface HeaderProps extends SanityHeaderFragment {
  isTransparent: boolean
  topObserverRef: RefObject<Element>
  logo?: SanityImageFragment
  invertedLogo?: SanityImageFragment
  onResize?: (newValues: HeaderSizeValues) => void
}

const Header = ({
  promo,
  menuDesktopLeft,
  menuDesktopRight,
  menuMobilePrimary,
  menuMobileSecondary,
  isTransparent,
  logo,
  invertedLogo,
  topObserverRef,
  onResize,
}: HeaderProps) => {
  const strings = useContext(StringsContext)
  const { megaNavigation, mobileMenu, toggleMegaNavigation, toggleMobileMenu } =
    useContext(SiteContext)

  const [isLoaded, setIsLoaded] = useState(false)

  const [isObserverVisible, setIsObserverVisible] = useState(isTransparent)
  const isInView = useIntersectionObserver({
    ref: topObserverRef,
    options: {
      triggerOnce: false,
      threshold: 1,
    },
  })
  const headerRef = useRef<HTMLDivElement>(null)
  const headerRect = useRect(headerRef)

  useEffect(
    () => setIsObserverVisible(isLoaded ? isInView : isTransparent),
    [isInView, isLoaded, isTransparent]
  )

  useEffect(() => {
    // Wait for intersection observer to load, then show header
    if (!isLoaded) {
      setTimeout(() => setIsLoaded(true), 200)
    }

    if (isLoaded && headerRect && onResize) {
      onResize({
        height: headerRect.height,
        bottom: headerRect.top + headerRect.height,
      })
    }
  }, [headerRect, isLoaded, onResize])

  return (
    <>
      <a
        href="#content"
        className="block fixed top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-90 px-2 py-1 bg-pageBG text-pageText text-xs font-semibold uppercase focus:translate-y-0 focus:outline-none"
      >
        {strings.skipToContent}
      </a>

      {promo?.enabled && (
        <PromoBar
          enabled={promo.enabled}
          display={promo.display}
          text={promo.text}
          link={promo.link}
        />
      )}

      <header
        className={cx('sticky top-0 inset-x-0 z-50 text-header-text', {
          'h-0': isTransparent,
        })}
      >
        <div ref={headerRef} className="relative">
          <div
            className={cx(
              'relative z-30 py-1 sm:py-4 md:py-5 transition-colors duration-300',
              {
                'bg-transparent': isTransparent && isObserverVisible,
                'bg-header-bg': !isTransparent,
                'bg-header-bg bg-opacity-95': !isObserverVisible,
                'text-pageBG delay-75':
                  isTransparent && !megaNavigation?.isOpen && isObserverVisible,
              }
            )}
          >
            <div className="px-5 sm:px-8 container flex flex-row justify-between items-center w-full z-30 min-h-[50px]">
              {/* Logo */}
              <Logo
                logo={logo}
                invertedLogo={invertedLogo}
                isInvertedLogo={isTransparent && isObserverVisible}
                className="z-60"
              />

              {/* Mobile Header Menu */}
              <nav
                id="mobile-nav"
                className={cx('lg:hidden flex justify-end items-center')}
              >
                <FocusTrap active={mobileMenu.isOpen}>
                  <div className="flex items-center">
                    {/* Menu toggle */}
                    <button
                      onClick={() => toggleMobileMenu(!mobileMenu.isOpen)}
                      aria-expanded={mobileMenu.isOpen ? 'true' : 'false'}
                      aria-controls="mobile-nav"
                      aria-label={strings.mobileMenuLabel}
                      className={cx(
                        'lg:hidden relative z-60 appearance-none no-underline cursor-pointer font-inherit rounded-full',
                        {
                          'text-pageText': mobileMenu.isOpen,
                          'transition-colors duration-300': isTransparent,
                        }
                      )}
                    >
                      <Hamburger isOpened={mobileMenu.isOpen} />
                    </button>

                    <motion.div
                      initial="hide"
                      animate={mobileMenu.isOpen ? 'show' : 'hide'}
                      variants={{
                        show: {
                          x: '0%',
                        },
                        hide: {
                          x: '-100%',
                        },
                      }}
                      transition={{ duration: 0.8, ease: [0.16, 1, 0.3, 1] }}
                      className="fixed top-0 left-0 w-full h-screen z-50 flex flex-col bg-pageBG text-pageText"
                    >
                      <div className="container">
                        <div className="flex-1 flex flex-col pb-4 pt-[calc(var(--headerBottom,10rem)+1.5rem)] overflow-y-scroll no-scrollbar">
                          {!!menuMobilePrimary?.items && (
                            <div>
                              <Menu
                                id={menuMobilePrimary.slug}
                                items={menuMobilePrimary.items}
                                onClick={() => toggleMobileMenu(false)}
                                isHeaderMobilePrimaryMenu
                              />
                            </div>
                          )}

                          {!!menuMobileSecondary?.items && (
                            <div className="pt-6">
                              <Menu
                                id={menuMobileSecondary.slug}
                                items={menuMobileSecondary.items}
                                onClick={() => toggleMobileMenu(false)}
                                isHeaderMobileSecondaryMenu
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </motion.div>

                    <div
                      className={cx(
                        'fixed inset-0 z-40 bg-backdrop bg-opacity-40 pointer-events-none transition-opacity duration-150 ease-linear',
                        {
                          'opacity-0': !mobileMenu.isOpen,
                          'pointer-events-auto backdrop-filter-blur-[6px] opacity-100':
                            mobileMenu.isOpen,
                        }
                      )}
                      onClick={() => toggleMobileMenu(false)}
                      role="presentation"
                    />
                  </div>
                </FocusTrap>
              </nav>

              {/* Desktop Header Menu */}
              <nav className="hidden lg:flex items-center justify-center py-2">
                {!!menuDesktopLeft?.items && (
                  <Menu
                    id={menuDesktopLeft.slug}
                    items={menuDesktopLeft.items}
                    onClick={() => toggleMegaNavigation(false)}
                    useMegaNav
                    isHeaderDesktopMenu
                  />
                )}
              </nav>

              <nav className="hidden lg:flex items-center relative z-1">
                {!!menuDesktopRight?.items && (
                  <Menu
                    id={menuDesktopRight.slug}
                    items={menuDesktopRight.items}
                    onClick={() => toggleMegaNavigation(false)}
                    useMegaNav
                    isHeaderDesktopMenu
                  />
                )}
              </nav>
            </div>
          </div>

          <MegaNavigation
            items={[
              ...(menuDesktopLeft?.items ?? []),
              ...(menuDesktopRight?.items ?? []),
            ]}
            headerHeight={headerRect?.height ?? 0}
          />
        </div>
      </header>
    </>
  )
}

export default Header
